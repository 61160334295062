<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col key="hstTipos" :cols="2">
          <v-card v-if="tipos">
            <v-card-title>Tipos de Reports</v-card-title>
            <v-data-table
              v-model="tiposSelected"
              :headers="tiposHeader"
              :items="tipos"
              :single-select="false"
              show-select
              no-data-text="Sem resultados"
              no-results-text="Sem resultados"
              hide-default-footer>
              <template v-slot:[`item.icon`]="{ item }">
                <v-icon large :color="item.cor" tile>
                  mdi-{{ item.icon }}
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
          <br><br>
        </v-col>
        <v-col key="Histórico" :cols="10">
          <v-card v-if="historico">
            <v-card-title>
              Histórico de Reports
              <v-btn
                title="Criar report"
                class="mx-2"
                fab

                small
                color=primary
                @click="
                  getContentors();
                  addReport = true;">
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                fab

                small
                color="rgb(29, 111, 66)"
                @click="
                  reportXLS(false)">
                <v-icon title="Exportar todos os dados">mdi-file-excel-box</v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                fab

                small
                color="rgb(29, 111, 66)"
                @click="
                  reportXLS(true)">
                <v-icon title="Exportar dados com filtro">mdi-filter</v-icon>
              </v-btn>
            </v-card-title>
            <v-dialog v-model="addReport" style="z-index: 9999" width="500">
              <v-card :disabled = "loaders.savingReport">
                <v-card-title class="headline grey lighten-2" primary-title>
                  Contentor
                </v-card-title>
                <v-card-text>
                  <v-form ref="form">
                    <v-autocomplete
                      v-model="newReport.contentor"
                      :items="contentors"
                      item-text="autocomplete"
                      label="Contentores"
                      class="mr-4"
                      return-object
                      required>
                    </v-autocomplete>
                    <br />Precisa de Higenização?
                    <div class="text-center">
                      <v-btn
                        @click="newReport.precisaLimpeza = true"
                        class="ma-2"
                        large
                        :color="newReport.precisaLimpeza ? 'success' : ''">
                        Sim
                      </v-btn>
                      <v-btn
                        @click="newReport.precisaLimpeza = false"
                        class="ma-2"
                        large
                        :color="
                          !newReport.precisaLimpeza
                            ? newReport.precisaLimpeza == null
                              ? ''
                              : 'success'
                            : ''
                        ">
                        Não
                      </v-btn>
                    </div>
                    <br />Tem monstro?
                    <div class="text-center">
                      <v-btn
                        @click="newReport.monstros = true"
                        class="ma-2"
                        large
                        :color="newReport.monstros ? 'success' : ''">
                        Sim
                      </v-btn>
                      <v-btn
                        @click="newReport.monstros = false"
                        class="ma-2"
                        large
                        :color="
                          !newReport.monstros
                            ? newReport.monstros == null
                              ? ''
                              : 'success'
                            : ''
                        ">
                        Não
                      </v-btn>
                    </div>
                    <v-btn
                      color="success"
                      class="mr-4"
                      @click="saveReport();"
                      :loading = "loaders.savingReport"
                      :disabled = "loaders.savingReport">
                      Salvar
                    </v-btn>
                    <v-btn color="error" class="mr-4" @click="addReport = false">
                      Cancelar
                    </v-btn>
                  </v-form>
                </v-card-text>

                <v-divider></v-divider>
              </v-card>
            </v-dialog>
            <v-card-title>
              <v-menu
                ref="menuDataInicio"
                v-model="menuDataInicio"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px">

                <template v-slot:activator="{ on }">
                  <v-btn class="mx-2"
                    color=primary
                    small
                    v-on="on">
                    Data Inicial:
                    {{
                      dataInicio.split("-")[2] +
                      "/" +
                      dataInicio.split("-")[1] +
                      "/" +
                      dataInicio.split("-")[0]
                    }}
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="dataInicio"
                  locale="pt-pt"
                  no-title
                  @input="menuDataInicio = false">
                </v-date-picker>
              </v-menu>
              <v-menu
                ref="menuDataFim"
                v-model="menuDataFim"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-btn class="mx-2"
                    color=primary
                    small
                    v-on="on">
                    Data Final:
                    {{
                      dataFim.split("-")[2] +
                      "/" +
                      dataFim.split("-")[1] +
                      "/" +
                      dataFim.split("-")[0]
                    }}
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="dataFim"
                  locale="pt-pt"
                  no-title
                  @input="menuDataFim = false">
                </v-date-picker>
              </v-menu>
            </v-card-title>
            <v-card-title></v-card-title>

            <v-data-table
              :headers="historicoHeader"
              :items="historico"
              :loading="loaders.reportDiario"
              loading-text="A carregar..."
              :search="search"
              no-data-text="Sem resultados"
              no-results-text="Sem resultados"
              hide-default-footer>
              <template v-slot:top="{ pagination, options, updateOptions }">
                <v-data-footer
                  :pagination="pagination"
                  :options="options"
                  @update:options="updateOptions"
                  items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
              </template>
              <template v-slot:[`item.sensor`]="{ item }">
                <v-icon
                  title="Sensor Ligado"
                  color="green"

                  v-if="item.sensor">
                  mdi-wifi
                </v-icon>
                <v-icon title="Sem Sensor" color="grey" v-if="!item.sensor">
                  mdi-wifi
                </v-icon>
              </template>
              <template v-slot:[`item.fotos`]="{ item }">
                <a
                  v-for="foto in item.fotos"
                  :key="foto.id"
                  :href="foto.url"
                  target="_blank">
                  <img :src="foto.url" height="70px" width="70px" />
                </a>
              </template>

              <template v-slot:[`item.report`]="{ item }">
                <v-icon
                  title="Lixo recolhido"
                  large

                  :color="item.recolheuLixo ? 'green' : 'grey'"
                  tile>
                  mdi-check
                </v-icon>
                <v-icon
                  title="Lixo fora do contentor"

                  :color="item.lixofora ? '#ebbf41' : 'grey'"
                  tile>
                  mdi-basket-unfill
                </v-icon>
                <!--<v-icon
                  title="Tampa Partida"

                  :color="item.tampaPartida ? '#347a8d' : 'grey'"
                  tile>
                  mdi-delete-empty
                </v-icon>-->
                <v-icon
                  :color="item.temAvaria ? '#f24747' : 'grey'"
                  title="Tem Anomalia">
                  mdi-delete-forever
                </v-icon>
                <v-icon
                  title="Precisa de higienização"

                  :color="item.precisaLimpeza ? '#fa8748' : 'grey'"
                  tile>
                  mdi-broom
                </v-icon>
                <v-icon
                  title="Tem monstro"

                  :color="item.monstros ? '#6e6eba' : 'grey'">
                  mdi-seat
                </v-icon>
                <v-icon
                  title="Fez higienização"

                  :color="item.fezHigenizacao ? '#ff00ff' : 'grey'"
                  tile>
                  mdi-spray-bottle
                </v-icon>
                <v-icon
                  title="Recolheu Monstro"

                  :color="item.recolheuMostros ? '#3366ff' : 'grey'"
                  tile>
                  mdi-arrow-collapse-up
                </v-icon>
                <v-icon
                  title="Fez Manutenção"

                  :color="item.fezManutencao ? '#ffffff' : 'grey'"
                  tile>mdi-delete-circle-outline
                </v-icon>
                <br />
                <span v-if="item.obsLixo" class="mx-2">
                  <b>Obs:</b> {{ item.obsLixo }}
                </span>
                <span v-if="item.obsAvaria" class="mx-2">
                  <b>Anomalia:</b> {{ item.obsAvaria }}
                </span>
              </template>

              <template v-slot:[`item.position`]="{ item }">
                <v-btn
                  class="mx-2"
                  fab

                  small
                  color=primary
                  @click="centerZoom(item.position)">
                  <v-icon>
                    mdi-magnify-plus
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.nivel`]="{ item }">
                <v-chip
                  :color="getColorNivelSensorTabela(item.nivel)"

                  v-if="item.nivel">
                  {{ item.nivel }}%
                </v-chip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <br><br>
  </div>
</template>

<script>
/* eslint-disable */
import { icon, latLng } from "leaflet";
import mixin from '../plugins/mixin'
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LTooltip,
  LGeoJson,
  LIcon,
  LPolyline,
} from "vue2-leaflet";
import { filter } from "vuedraggable";

export default {
  name: "reportDiario",
  mixins: [mixin],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LGeoJson,
    LIcon,
    LPolyline,
  },
  data: () => ({
    API_URL: process.env.VUE_APP_API_URL,
    salvando: false,
    addReport: false,
    contentors: [],
    newReport: {
      contentor: {},
      monstros: false,
      precisaLimpeza: false,
      caminhao: 34,
    },
    tiposHeader: [
      { text: "", value: "icon" },
      { text: "Tipo", value: "tipo" },
    ],
    tipos: [
      {
        id: 1,
        tipo: "Recolha de lixo",
        icon: "check",
        cor: "green",
        cd: "recolheuLixo",
      },
      /*{
        id: 2,
        tipo: "Tampa partida",
        icon: "delete-empty",
        cor: "#347a8d",
        cd: "tampaPartida",
      },*/
      {
        id: 3,
        tipo: "Tem anomalia",
        icon: "delete-forever",
        cor: "#f24747",
        cd: "temAvaria",
      },
      {
        id: 4,
        tipo: "Precisa de higienização",
        icon: "broom",
        cor: "#fa8748",
        cd: "precisaLimpeza",
      },
      {
        id: 5,
        tipo: "Lixo fora do contentor",
        icon: "basket-unfill",
        cor: "#ebbf41",
        cd: "lixofora",
      },
      {
        id: 6,
        tipo: "Tem monstro",
        icon: "seat",
        cor: "#6e6eba",
        cd: "monstros",
      },
      {
        id: 7,
        tipo: "Fez higienização",
        icon: "spray-bottle",
        cor: "#ff00ff",
        cd: "fezHigenizacao",
      },
      {
        id: 8,
        tipo: "Recolhido monstro",
        icon: "arrow-collapse-up",
        cor: "#3366ff",
        cd: "recolheuMostros",
      },
      {
        id: 9,
        tipo: "Fez manutenção",
        icon: "delete-circle-outline",
        cor: "#ffffff",
        cd: "fezManutencao",
      },
    ],
    tiposSelected: [
      /*{
        id: 2,
        tipo: "Tampa partida",
        icon: "delete-empty",
        cor: "#347a8d",
        cd: "tampaPartida",
      },*/
      {
        id: 3,
        tipo: "Tem anomalia",
        icon: "delete-forever",
        cor: "#f24747",
        cd: "temAvaria",
      },
      {
        id: 4,
        tipo: "Precisa de higienização",
        icon: "broom",
        cor: "#fa8748",
        cd: "precisaLimpeza",
      },
      {
        id: 5,
        tipo: "Lixo fora do contentor",
        icon: "basket-unfill",
        cor: "#ebbf41",
        cd: "lixofora",
      },
      {
        id: 6,
        tipo: "Tem monstro",
        icon: "seat",
        cor: "#6e6eba",
        cd: "monstros",
      },
    ],
    historicoHeader: [
      { text: "Contentor", value: "contentor", align: 'center' },
      { text: "Sensor", value: "sensor", align: 'center' },
      { text: "Data", value: "data", align: 'center' },
      { text: "Reports", value: "report", align: 'center', fixed: true, width: "220px" },
      { text: "Rua", value: "rua", align: 'center' },
      { text: "Freguesia", value: "freguesia", align: 'center' },
      { text: "Rota", value: "rota", align: 'center' },
      { text: "Motorista", value: "motorista", align: 'center' },
      { text: "Último Nivel", value: "nivel", align: 'center' },
      { text: "Fotos", value: "fotos", align: 'center' },
    ],
    historico: [],
    historicoRoot: [],
    reports: {},
    dataInicio: new Date().toISOString().substr(0, 10),
    dataFim: new Date().toISOString().substr(0, 10),
    dateFormatted: new Date(),
    menuDataInicio: false,
    menuDataFim: false,
    combustiveis: [],
    combustiveisRoot: [],
    combustiveisTotal: 0,
    rotas: [],
    cartrack: [],
    search: "",
    selecionado: false,
    markers: [
      {
        id: "m1",
        position: { lat: 47.41322, lng: -1.2 },
        tooltip: "tooltip for marker1",
        icon: icon.glyph({
          prefix: "mdi",
          glyph: "trash-can-outline",
        }),
      },
    ],
    caminhoes: [],
    zoom: 11,
    center: latLng(41.460349, -8.3771063, 12),
    url:
      "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
    attribution: "",
    withPopup: latLng(47.41322, -1.219482),
    withTooltip: latLng(47.41422, -1.250482),
    currentZoom: 11.5,
    currentCenter: latLng(47.41322, -1.219482),
    showParagraph: false,
    mapOptions: {
      zoomSnap: 0.5,
    },
    showMap: true,
    geojson: null,
    iconSize: 45,
    loaders: {
      reportDiario: false,
      savingReport: false,
    },
  }),
  methods: {
    saveReport() {
      this.loaders.savingReport = true;
      this.newReport.user = this.getObjeto("user").id;
      this.newReport.data = new Date();
      this.$http
        .post("reports", this.newReport, {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((response) => {
          this.newReport = {
            contentor: {},
            monstros: false,
            precisaLimpeza: false,
            caminhao: 34, //check for mandatory field
          };
          this.getReports();
          this.addReport = false;
          this.loaders.savingReport = false;
        })
        .catch((error) => {
          console.log(error);
          this.loaders.savingReport = false;
        });
    },
    getlatLng: function (lat, lng) {
      return { lat, lng };
    },
    updateZoom: function (e) {
      this.zoom = e;
    },
    centerZoom(position) {
      this.zoom = 17;
      this.zoomUpdate = 17;
      this.center = latLng(0, 0);
      setTimeout(() => {
        this.center = position;
      }, 500);
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },

    getReports() {
      this.loaders.reportDiario = true;
      this.historico = [];
      this.historicoRoot = [];
      var inicio = new Date(this.dataInicio);
      inicio.setDate(inicio.getDate());
      var fim = new Date(this.dataFim);
      fim.setDate(fim.getDate() + 1);

      this.$http
        .get(
          "reports/count?created_at_gte=" +
            inicio.toISOString() +
            "&created_at_lt=" +
            fim.toISOString(),
          {
            headers: {
              Authorization: `Bearer ${this.getObjeto("token")}`,
            },
          }
        )
        .then((qtsReports) => {
          for (
            let index = 0;
            //GraphQL foi configurado para enviar no máximo 800 resultados
            //Strapi -> app/config/plugins.js
            index < Math.round(qtsReports.data / 800) + 1;
            index++
          ) {
            this.$http
              .get("graphql?query={ \
                reports(start: "+(index*800)+", sort: \"id:desc\", \
                where:{created_at_gte:\""+inicio.toISOString()+"\",created_at_lt:\""+fim.toISOString()+"\"}) \
                {id, data, contentor {sensor, numero_contentor, rua, lat, lng, localidade { id, designacao } }, \
                caminhao {matricula}, user {nome}, rota {nome}, \
                recolheuLixo, tampaPartida, lixofora, temAvaria, precisaLimpeza, monstros, \
                recolheuMostros, fezHigenizacao, fezManutencao, nivel, fotos{name,url}, obsAvaria, obsLixo } }",
                {
                  headers: {
                    Authorization: `Bearer ${this.getObjeto("token")}`,
                  },
                }
              )
              .then((response) => {
                response.data.data.reports.forEach((i) => {
                  try{
                    i.fotos.forEach((foto) => {
                      foto.url = this.API_URL+foto.url;
                    });
                    let timestamp = new Date(i.data).getTime();
                    var report = {
                      id: i.id,
                      sensor: i.contentor.sensor ? true : false,
                      dataInterno: new Date(i.data),
                      data: this.dateToYYYYMMDD(timestamp) + "\n" + this.dateToHHMM(timestamp),

                      motorista: i.user.nome,
                      rota: i.rota ? i.rota.nome : "",
                      contentor: i.contentor.numero_contentor,
                      rua: i.contentor.rua,
                      freguesia: i.contentor.localidade.designacao,
                      recolheuLixo: i.recolheuLixo,
                      report: true,
                      tampaPartida: i.tampaPartida ? true : false,
                      lixofora: i.lixofora,
                      temAvaria: i.temAvaria,
                      precisaLimpeza: i.precisaLimpeza,
                      monstros: i.monstros,
                      recolheuMostros: i.recolheuMostros,
                      fezHigenizacao: i.fezHigenizacao,
                      fezManutencao: i.fezManutencao,
                      nivel: i.nivel,
                      fotos: i.fotos,
                      position: latLng(i.contentor.lat, i.contentor.lng),
                      obsAvaria: i.obsAvaria,
                      obsLixo: i.obsLixo,
                    };
                    this.historicoRoot.push(report);
                  }catch(e){
                    console.log("error on:"+i);
                  }
                });
                this.filterReports();
                this.loaders.reportDiario = false;
              })
              .catch((error) => {
                console.log(error);
                this.loaders.reportDiario = false;
              })
            }
          });
    },
    reportXLS(filtered=false){
      if (filtered) {
        var filtros = this.tiposSelected.map(function(item) {
          return item.cd;
        });

        this.$http
          .post("relatorios/reportxls/" + this.dataInicio + "/" + this.dataFim, filtros, {
            headers: {
              Authorization: `Bearer ${this.getObjeto("token")}`,
            },
          })
          .then((response) => {

            this.$router.push();

            var link = document.createElement("a");
            link.href = this.API_URL+"/" + response.data;
            link.target = "_blank";
            link.download = "fileName";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch(() => {});
      } else {
        this.$http
          .get("relatorios/reportxls/" + this.dataInicio + "/" + this.dataFim, {
            headers: {
              Authorization: `Bearer ${this.getObjeto("token")}`,
            },
          })
          .then((response) => {

            this.$router.push();

            var link = document.createElement("a");
            link.href = this.API_URL+"/" + response.data;
            link.target = "_blank";
            link.download = "fileName";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch(() => {});
      }
    },
    getObjeto(nome) {
      return localStorage.getItem(nome)
        ? JSON.parse(localStorage.getItem(nome))
        : null;
    },
    setObjeto(nome, objeto) {
      return localStorage.setItem(nome, JSON.stringify(objeto));
    },
    convertData(data) {
      return new Date(data).toLocaleString();
    },
    filtro() {
      //TODO: verify dates => start < end, etc...
      this.getReports();
    },
    filterReports() {
      let filterActive = this.tiposSelected;
      this.historico = this.historicoRoot.filter(function (obj1) {
        return filterActive.some(function(obj2){
          return obj1[obj2.cd] === true;
        })
      });
    },
    getContentors() {
      this.$http
        .get("contentors?_limit=50000&status=true", {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((contentors) => {
          this.contentors = contentors.data;
          this.contentors.forEach((item) => {
            item.autocomplete =
              item.numero_contentor +
              " - " +
              item.rua +
              " - " +
              (item.referencia ? item.referencia + " - " : "") +
              item.localidade.designacao;
          });
        });
    },
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  created() {
    this.getReports();
  },
  watch: {
    tiposSelected: function (val) {
      this.historico = [];
      this.filterReports();
    },
    dataInicio: function (val) {
      this.filtro();
    },
    dataFim: function (val) {
      this.filtro();
    },
  },
};
</script>
